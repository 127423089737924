import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledPanel = styled.div`
  padding: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledPanelHeader = styled.div<IPanelHeaderProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[0]};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};

  ${(props) =>
    props.variant === "secondary" &&
    css`
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
      background-image: ${({ theme }) => theme.colors.primaryGradient};
      color: ${({ theme }) => theme.colors.white};
      text-transform: uppercase;
    `}
`;

const PanelHeaderIcon = styled.div<IPanelHeaderProps>`
  width: 6px;
  height: 26.5px;
  margin-right: 8px;
  fill: ${({ theme }) => theme.colors.white};

  ${(props) =>
    props.variant === "dark" &&
    css`
      fill: ${({ theme }) => theme.colors.primary};
    `}
`;

const PanelHeading = styled.h4`
  flex: 1;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: 20px;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.base};
`;

const StyledPanelFooter = styled.div`
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.whiteGrey};
  text-align: center;
`;

interface IPanelHeaderProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  variant?: string;
}

const PanelHeader: React.FC<IPanelHeaderProps> = ({
  children,
  title,
  variant,
}) => (
  <StyledPanelHeader variant={variant}>
    <PanelHeaderIcon variant={variant}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="26.5"
        viewBox="0 0 6 25"
      >
        <rect width="6" height="26.5" />
      </svg>
    </PanelHeaderIcon>
    <PanelHeading>{title}</PanelHeading>
    {children}
  </StyledPanelHeader>
);

interface IPanelBodyProps {
  children: React.ReactNode;
}

const PanelBody: React.FC<IPanelBodyProps> = ({ children }) => (
  <div>{children}</div>
);

interface IPanelFooterProps {
  children: React.ReactNode;
}

const PanelFooter: React.FC<IPanelFooterProps> = ({ children }) => (
  <StyledPanelFooter>{children}</StyledPanelFooter>
);

interface IPanelProps {
  children?: React.ReactNode;
}

class Panel extends React.Component<IPanelProps> {
  public static Header = PanelHeader;
  public static Body = PanelBody;
  public static Footer = PanelFooter;

  render() {
    const { children } = this.props;
    return (
      <>
        <StyledPanel>{children}</StyledPanel>
      </>
    );
  }
}

export default Panel;

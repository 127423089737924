import { navigate, RouteComponentProps } from "@reach/router";
import range from "lodash/range";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import moment from "moment";
import React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { bootstrap } from "../../core/store/bootstrap/thunks";
import {
  getPlayerData,
  getPlayerUpdateError,
  getRegisterError,
} from "../../core/store/player/reducers";
import { playerUpdate, register } from "../../core/store/player/thunks";
import { getRegions } from "../../core/store/regions/reducers";
import { fetchRegions } from "../../core/store/regions/thunks";
import { IRegion } from "../../core/store/regions/types";
import { IPlayer } from "../../types";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import {
  CheckboxField,
  FieldFeedback,
  InputField,
  RadioGroup,
  SelectField,
} from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import DeleteAccountBox from "./DeleteAccountBox";
import PasswordChange from "./PasswordChange";

const DOBFieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
`;

const DOBFieldsetInner = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.space[2]};
    margin-left: -${({ theme }) => theme.space[2]};
    margin-right: -${({ theme }) => theme.space[2]};
  }
`;

const DOBLegend = styled.legend`
  display: block;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.space[1]};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const DOBCol = styled.div`
  margin-top: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: calc(100% / 3);
    margin-top: 0;
    padding-left: ${({ theme }) => theme.space[2]};
    padding-right: ${({ theme }) => theme.space[2]};
  }
`;

interface IPropsFromState {
  player: IPlayer | null;
  registerError: IError | null;
  updateError: IError | null;
  regions: IRegion[];
}

interface IPropsFromDispatch {
  doBootstrap: () => Promise<void>;
  registerPlayer: (data: {}) => Promise<void>;
  updatePlayer: (data: {}) => Promise<void>;
  fetchRegionData: () => void;
}

type Props = RouteComponentProps &
  WithTranslation &
  IPropsFromState &
  IPropsFromDispatch;

interface IState {
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  errorEmail: string;
  errorFName: string;
  errorLName: string;
  errorPass: string;
  region: number;
  emailAllDam: boolean;
  errorRegion: string;
  gender: string;
  errorGender: string;
  formDisabled: boolean;
  errorDob: string;
  emailFavourite: boolean;
}

class Register extends React.Component<Props, IState> {
  public errorsTxt: { [key: string]: string } = {
    EDob: this.props.t("register.errors.dobn", "Date of birth not valid"),
    EEmail: this.props.t("register.errors.email", "An email is required"),
    EFName: this.props.t("register.errors.fname", "First name is required"),
    ELName: this.props.t("register.errors.lname", "Last name is required"),
    EPass: this.props.t("register.errors.pass", "A password is required"),
    EPassLength: this.props.t(
      "register.errors.passLength",
      "Password too short, minimum 6 characters"
    ),
    EValidEmail: this.props.t(
      "register.errors.validEmail",
      "This Email address is already registered"
    ),
    EValidPass: this.props.t("register.errors.validPass", "Password not valid"),
    ERegion: this.props.t("register.errors.region", "A region is required"),
    EGender: this.props.t(
      "register.errors.gender",
      "A gender choice is required"
    ),
  };

  public months: { [key: string]: string } = {
    1: this.props.t("months.janShort", "Jan"),
    2: this.props.t("months.febShort", "Feb"),
    3: this.props.t("months.marShort", "Mar"),
    4: this.props.t("months.aprShort", "Apr"),
    5: this.props.t("months.mayShort", "May"),
    6: this.props.t("months.junShort", "Jun"),
    7: this.props.t("months.julShort", "Jul"),
    8: this.props.t("months.augShort", "Aug"),
    9: this.props.t("months.sepShort", "Sep"),
    10: this.props.t("months.octShort", "Oct"),
    11: this.props.t("months.novShort", "Nov"),
    12: this.props.t("months.decShort", "Dec"),
  };

  constructor(props: Props) {
    super(props);
    const player = props.player;
    this.state = {
      birthDay:
        player && player.date_of_birth
          ? Number(player.date_of_birth.split("-")[2])
          : 0,
      birthMonth:
        player && player.date_of_birth
          ? Number(player.date_of_birth.split("-")[1])
          : 0,
      birthYear:
        player && player.date_of_birth
          ? Number(player.date_of_birth.split("-")[0])
          : 0,
      email:
        player && player.email.indexOf("@ismfgsocial") === -1
          ? player.email
          : "",
      emailFavourite: player ? player.email_favourite : false,
      errorDob: "",
      errorEmail: "",
      errorFName: "",
      errorLName: "",
      errorPass: "",
      firstName: player ? player.first_name : "",
      lastName: player ? player.last_name : "",
      password: "",
      emailAllDam: player ? player.email_alldam : false,
      errorRegion: "",
      region: props.player ? props.player.region : 0,
      gender: props.player ? props.player.gender : "",
      errorGender: "",
      formDisabled: false,
    };
  }

  public getMode = () => {
    if (!this.props.player) {
      return "register";
    }
    return this.props.player.dirty ? "confirm" : "update";
  };

  public getRequiredFields(): Array<keyof IState> {
    switch (this.getMode()) {
      case "register":
        return [
          "birthDay",
          "birthMonth",
          "birthYear",
          "email",
          "firstName",
          "lastName",
          "password",
          "region",
          "gender",
        ];
      case "confirm":
        return [
          "birthDay",
          "birthMonth",
          "birthYear",
          "email",
          "firstName",
          "lastName",
          "region",
          "gender",
        ];
      case "update":
        return ["email", "firstName", "lastName", "gender", "region"];
    }
  }

  public apiDataFromState() {
    switch (this.getMode()) {
      case "register":
        return {
          date_of_birth: `${this.state.birthYear}-${this.state.birthMonth}-${this.state.birthDay}`,
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          region: this.state.region,
          email_alldam: this.state.emailAllDam,
          email_favourite: this.state.emailFavourite,
          gender: this.state.gender,
        };
      case "confirm":
      case "update":
        return {
          date_of_birth: `${this.state.birthYear}-${this.state.birthMonth}-${this.state.birthDay}`,
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          region: this.state.region,
          email_alldam: this.state.emailAllDam,
          email_favourite: this.state.emailFavourite,
          gender: this.state.gender,
        };
    }
  }

  public handleBirthDayChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ birthDay: parseInt(e.currentTarget.value, 10) });

  public handleBirthMonthChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ birthMonth: parseInt(e.currentTarget.value, 10) });

  public handleBirthYearChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ birthYear: parseInt(e.currentTarget.value, 10) });

  public handleEmailChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ email: e.currentTarget.value, formDisabled: false });

  public handleFirstNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ firstName: e.currentTarget.value });

  public handleLastNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ lastName: e.currentTarget.value });

  public handleGenderChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ gender: e.currentTarget.value });

  public handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ password: e.currentTarget.value, formDisabled: false });

  public handleRegionChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ region: parseInt(e.currentTarget.value, 10) });

  public handleemailAllDamChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ emailAllDam: e.currentTarget.checked ? true : false });

  public handleEmailFavouriteChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ emailFavourite: e.currentTarget.checked ? true : false });

  public handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formDisabled: true });
    const mode = this.getMode();
    if (mode === "register") {
      try {
        await this.props.registerPlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        return;
      }
      await this.props.doBootstrap();
      navigate("/squad-selection");
    } else if (mode === "confirm") {
      try {
        await this.props.updatePlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        return;
      }
      await this.props.doBootstrap();
      navigate("/squad-selection");
    } else if (mode === "update") {
      try {
        await this.props.updatePlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        return;
      }
      await this.props.doBootstrap();
      // Maybe this should be back to my team, maybe our LoggedIn component
      navigate("/");
    }
    this.setState({ formDisabled: false });
  };

  public componentDidMount() {
    this.props.fetchRegionData();
  }
  public getSwedishRegions() {
    const regs = this.props.regions;
    const saudi = regs.filter((reg) => reg.code === 204);
    return sortBy(saudi, ["name"]);
  }

  public getIntRegions() {
    const regs = this.props.regions;
    const noSwedish = regs.filter((reg) => reg.code !== 204);
    return sortBy(noSwedish, ["name"]);
  }

  public handleFirstnameBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorFName: this.errorsTxt.EFName });
    } else {
      this.setState({ errorFName: "" });
    }
  };

  public handleLastnameBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorLName: this.errorsTxt.ELName });
    } else {
      this.setState({ errorLName: "" });
    }
  };

  public handlePasswordBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorPass: this.errorsTxt.EPass });
    } else {
      if (e.currentTarget.value.length < 6) {
        this.setState({ errorPass: this.errorsTxt.EPassLength });
      } else {
        this.setState({ errorPass: "" });
      }
    }
  };

  public handleEmailBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorEmail: this.errorsTxt.EEmail });
    } else {
      this.setState({ errorEmail: "" });
    }
  };

  public handleRegionBlur = (e: React.FormEvent<HTMLSelectElement>) => {
    if (!e.currentTarget.value || e.currentTarget.value === "0") {
      this.setState({ errorRegion: this.errorsTxt.ERegion });
    } else {
      this.setState({ errorRegion: "" });
    }
  };

  public handleDobBlur = (e: React.FormEvent<HTMLSelectElement>) => {
    const year = this.state.birthYear;
    const month = this.state.birthMonth;
    const day = this.state.birthDay;
    if (year > 0 && month > 0 && day > 0) {
      if (moment(new Date(year, month - 1, day)).isValid()) {
        const today = new Date();
        const birthDate = new Date(year, month - 1, day);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 13) {
          this.setState({ errorDob: this.errorsTxt.EDob });
          this.setState({ formDisabled: true });
        } else {
          this.setState({ errorDob: "" });
          this.setState({ formDisabled: false });
        }
      } else {
        this.setState({ errorDob: this.errorsTxt.EDob });
        this.setState({ formDisabled: true });
      }
    }
  };

  public handleServerErrors = () => {
    const errors =
      this.getMode() === "register"
        ? this.props.registerError
        : this.props.updateError;
    const formattedErrors = [];
    if (errors) {
      if (
        errors.badRequest &&
        errors.badRequest.password &&
        errors.badRequest.password[0].code === "invalid"
      ) {
        formattedErrors.push(this.errorsTxt.EValidPass);
      }
      if (
        errors.badRequest &&
        errors.badRequest.email &&
        errors.badRequest.email[0].code === "duplicate"
      ) {
        formattedErrors.push(this.errorsTxt.EValidEmail);
      }
      if (
        errors.badRequest &&
        errors.badRequest.email &&
        errors.badRequest.email[0].code === "blank"
      ) {
        formattedErrors.push(this.errorsTxt.EEmail);
      }
      if (
        errors.badRequest &&
        errors.badRequest.password &&
        errors.badRequest.password[0].code === "blank"
      ) {
        formattedErrors.push(this.errorsTxt.EPass);
      }
      if (
        errors.badRequest &&
        errors.badRequest.last_name &&
        errors.badRequest.last_name[0].code === "blank"
      ) {
        formattedErrors.push(this.errorsTxt.EFName);
      }
      if (
        errors.badRequest &&
        errors.badRequest.last_name &&
        errors.badRequest.last_name[0].code === "blank"
      ) {
        formattedErrors.push(this.errorsTxt.ELName);
      }
      if (
        errors.badRequest &&
        errors.badRequest.region &&
        errors.badRequest.region[0].code === "does_not_exist"
      ) {
        formattedErrors.push(this.errorsTxt.ERegion);
      }

      return formattedErrors;
    }
  };

  public isFormDisabled = () => {
    const requiredFieldsMissing = this.getRequiredFields().some(
      (e) => !this.state[e]
    );
    return requiredFieldsMissing || this.state.formDisabled;
  };

  public render() {
    const { player, t } = this.props;
    const mode = this.getMode();
    const title = {
      register: t("register.title.registration", "Registration"),
      confirm: t("register.title.confirm", "Confirm your details"),
      update: t("register.title.update", "Confirm your details"),
    };
    const buttonText = {
      register: t("register.label.register", "Register"),
      confirm: t("register.label.confirm", "Update"),
      update: t("register.label.update", "Update"),
    };
    const errors = this.handleServerErrors();

    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>{title[mode]}</Title>
            <p>
              {t(
                "register.required",
                "Fields marked with an asterisk (*) are required."
              )}
            </p>
          </Box>

          {errors && <Alert type="error">{values(errors).map((r) => r)}</Alert>}
          <Copy>
            <form onSubmit={this.handleSubmit}>
              <Box>
                <InputField
                  required
                  id="ismRegisterEmail"
                  type="email"
                  hasErrors={!!this.state.errorEmail}
                  label={t("register.label.email", "Email")}
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  onBlur={this.handleEmailBlur}
                />
                {this.state.errorEmail && (
                  <FieldFeedback>{this.state.errorEmail}</FieldFeedback>
                )}
              </Box>
              {mode === "register" && (
                <Box mt={2}>
                  <InputField
                    id="ismRegisterPassword"
                    type="password"
                    hasErrors={!!this.state.errorPass}
                    label={t("register.label.password", "Password")}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    hint={t("register.hint.passsword", "Minimum 6 characters")}
                    onBlur={this.handlePasswordBlur}
                  />
                  {this.state.errorPass && (
                    <FieldFeedback>{this.state.errorPass}</FieldFeedback>
                  )}
                </Box>
              )}
              <Box mt={2}>
                <InputField
                  id="ismRegisterFirstName"
                  hasErrors={!!this.state.errorFName}
                  label={t("register.label.fName", "First Name")}
                  value={this.state.firstName}
                  onChange={this.handleFirstNameChange}
                  onBlur={this.handleFirstnameBlur}
                />
                {this.state.errorFName && (
                  <FieldFeedback>{this.state.errorFName}</FieldFeedback>
                )}
              </Box>
              <Box mt={2}>
                <InputField
                  id="ismRegisterLastName"
                  label={t("register.label.lName", "Last Name")}
                  hasErrors={!!this.state.errorLName}
                  value={this.state.lastName}
                  onChange={this.handleLastNameChange}
                  onBlur={this.handleLastnameBlur}
                />
                {this.state.errorLName && (
                  <FieldFeedback>{this.state.errorLName}</FieldFeedback>
                )}
              </Box>
              <Box mt={2}>
                {this.state.errorRegion && (
                  <FieldFeedback>{this.state.errorRegion}</FieldFeedback>
                )}
                <SelectField
                  value={this.state.region}
                  onChange={this.handleRegionChange}
                  label={t("register.label.regionn", "Region")}
                  id="ismRegisterRegion"
                  onBlur={this.handleRegionBlur}
                >
                  <option value="0" aria-selected={!this.state.region}>
                    {t("register.label.select", "Select")}
                  </option>
                  <optgroup label={t("register.label.norway", "Sweden")}>
                    {values(this.getSwedishRegions()).map((r) => (
                      <option
                        value={r.id}
                        aria-selected={r.id === this.state.region}
                        key={r.id}
                      >
                        {r.name}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup
                    label={t("register.label.international", "International")}
                  >
                    {values(this.getIntRegions()).map((r) => (
                      <option
                        value={r.id}
                        aria-selected={r.id === this.state.region}
                        key={r.id}
                      >
                        {r.name}
                      </option>
                    ))}
                  </optgroup>
                </SelectField>
              </Box>
              <Box my={2}>
                <RadioGroup
                  defaultOptionIndex={
                    this.state.gender === "M"
                      ? 0
                      : this.state.gender === "F"
                      ? 1
                      : this.state.gender === "O"
                      ? 2
                      : this.state.gender === "U"
                      ? 3
                      : undefined
                  }
                  legend={t("register.label.gender", "Gender")}
                  name="gender"
                  onChange={this.handleGenderChange}
                  options={[
                    {
                      id: "ism-gender-male",
                      label: t("register.male", "Male"),
                      value: "M",
                    },
                    {
                      id: "ism-gender-female",
                      label: t("register.female", "Female"),
                      value: "F",
                    },
                    {
                      id: "ism-gender-other",
                      label: t("register.other", "Other"),
                      value: "F",
                    },
                    {
                      id: "ism-gender-unspecified",
                      label: t("register.unspecified", "Unspecified"),
                      value: "F",
                    },
                  ]}
                />
              </Box>
              <Box mt={2}>
                <DOBFieldset>
                  <DOBLegend>
                    {t("register.label.dob", "Date of birth")}
                  </DOBLegend>
                  <span>
                    {t(
                      "register.hint.dob",
                      "The game is only available to individuals aged 13 and over"
                    )}
                  </span>
                  <DOBFieldsetInner>
                    <DOBCol>
                      <SelectField
                        labelVisbility={false}
                        id="ismRegisterBirthDay"
                        value={this.state.birthDay}
                        onChange={this.handleBirthDayChange}
                        label={t("register.label.day", "Day")}
                        onBlur={this.handleDobBlur}
                      >
                        <option
                          value="0"
                          aria-selected={!this.state.birthDay}
                          disabled
                          defaultValue="0"
                        >
                          {t("register.label.day", "Day")}
                        </option>
                        {range(1, 32).map((d) => (
                          <option
                            value={d}
                            aria-selected={d === this.state.birthDay}
                            key={d}
                          >
                            {d}
                          </option>
                        ))}
                      </SelectField>
                    </DOBCol>
                    <DOBCol>
                      <SelectField
                        labelVisbility={false}
                        id="ismRegisterBirthMonth"
                        value={this.state.birthMonth}
                        onChange={this.handleBirthMonthChange}
                        label={t("register.label.month", "Month")}
                        onBlur={this.handleDobBlur}
                      >
                        <option
                          value="0"
                          aria-selected={!this.state.birthMonth}
                          disabled
                          defaultValue="0"
                        >
                          {t("register.label.month", "Month")}
                        </option>
                        {Object.keys(this.months).map((m) => (
                          <option
                            value={m}
                            aria-selected={
                              parseInt(m, 10) === this.state.birthMonth
                            }
                            key={m}
                          >
                            {this.months[m]}
                          </option>
                        ))}
                      </SelectField>
                    </DOBCol>
                    <DOBCol>
                      <SelectField
                        labelVisbility={false}
                        value={this.state.birthYear}
                        onChange={this.handleBirthYearChange}
                        label={t("register.label.year", "Year")}
                        id="ismRegisterBirthYear"
                        onBlur={this.handleDobBlur}
                      >
                        <option
                          value="0"
                          aria-selected={!this.state.birthYear}
                          disabled
                          defaultValue="0"
                        >
                          {t("register.label.year", "Year")}
                        </option>
                        {range(new Date().getFullYear() - 13, 1900).map((y) => (
                          <option
                            value={y}
                            aria-selected={y === this.state.birthYear}
                            key={y}
                          >
                            {y}
                          </option>
                        ))}
                      </SelectField>
                    </DOBCol>
                  </DOBFieldsetInner>
                  {this.state.errorDob && (
                    <FieldFeedback>{this.state.errorDob}</FieldFeedback>
                  )}
                </DOBFieldset>
              </Box>
              <Box mt={2}>
                <p>
                  {t(
                    "register.emailText",
                    "Receive information and newsletter from"
                  )}
                </p>
                <CheckboxField
                  checked={this.state.emailFavourite}
                  id="ismRegisterEmailFavourite"
                  label={t("register.label.emailClub", "Your favourite club")}
                  onChange={this.handleEmailFavouriteChange}
                />
                <CheckboxField
                  checked={this.state.emailAllDam}
                  id="ismRegisteremailAllDam"
                  label={t("register.label.emailClient", "Damallsvenskan")}
                  onChange={this.handleemailAllDamChange}
                />
              </Box>
              <Box mt={2}>
                {mode === "confirm" && (
                  <Trans i18nKey="register.terms1">
                    By updating your account details, you confirm that you
                    accept the&nbsp;
                    <a
                      href="/help/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      User Terms
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      href={t(
                        "register.integrityPolicy",
                        "https://www.svenskelitfotboll.se/privacy-policy-2/"
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EFD's Integrity Policy
                    </a>
                    .&nbsp;
                  </Trans>
                )}
                {mode === "update" && (
                  <Trans i18nKey="register.terms1">
                    By updating your account details, you confirm that you
                    accept the&nbsp;
                    <a
                      href="/help/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      User Terms
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      href={t(
                        "register.integrityPolicy",
                        "https://www.svenskelitfotboll.se/privacy-policy-2/"
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EFD's Integrity Policy
                    </a>
                    .&nbsp;
                  </Trans>
                )}
                {mode === "register" && (
                  <Trans i18nKey="register.terms3">
                    By creating an account, you confirm that you accept the
                    &nbsp;
                    <a
                      href="/help/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      User Terms
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      href={t(
                        "register.integrityPolicy",
                        "https://www.svenskelitfotboll.se/privacy-policy-2/"
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EFD's Integrity Policy
                    </a>
                    .&nbsp;
                  </Trans>
                )}
                <Trans i18nKey="register.terms2">
                  If you have opted to receive information from your favorite
                  team, you also confirm that you have read your&nbsp;
                  <a
                    href="https://www.svenskelitfotboll.se/integritetspolicy-klubbar/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    favorite club's Integrity Policy
                  </a>
                  .
                </Trans>
              </Box>
              <Box mt={2} mb={8}>
                <Button type="submit" disabled={this.isFormDisabled()}>
                  {buttonText[mode]}
                </Button>
              </Box>
            </form>
          </Copy>
          {mode === "update" && player && <PasswordChange />}
          {mode === "update" && player && <DeleteAccountBox />}
        </Main>
      </Wrapper>
    );
  }
}

export { Register as RegisterTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  player: getPlayerData(state) as IPlayer | null,
  registerError: getRegisterError(state),
  updateError: getPlayerUpdateError(state),
  regions: getRegions(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  doBootstrap: () => dispatch(bootstrap()),
  registerPlayer: (data) => dispatch(register(data)),
  updatePlayer: (data) => dispatch(playerUpdate(data)),
  fetchRegionData: () => dispatch(fetchRegions()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);

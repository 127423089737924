import * as React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import {
  showElementSummary,
  updateElementTypeControl,
} from "../../core/store/elements/thunks";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { IPick } from "../../core/store/my-team/types";
import { resetLastChange } from "../../core/store/squad/actions";
import {
  getErrors,
  getLastChange,
  getProposedElements,
  getSavedPicks,
  getSquadMode,
} from "../../core/store/squad/reducers";
import { removeElement, restoreElement } from "../../core/store/squad/thunks";
import {
  ILastChange,
  IProposedElements,
  ISavedPicks,
  ISquadErrors,
  SquadMode,
} from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import {
  formatRawAsISO,
  formatRawAsLocalI18n,
} from "../../core/utils/datetime";
import { integerToMoney } from "../../core/utils/money";
import { dateLocales } from "../../i18n";
import Alert, { AlertWrap } from "../Alert";
import Button from "../Button";
import DeadlineBar from "../DeadlineBar";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogHeading from "../DialogHeading";
import DialogManager from "../DialogManager";
import Pitch, { PitchRow, PitchUnit } from "../Pitch";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import SaveBar from "./SaveBar";
import Scoreboard from "./Scoreboard";
import SquadPitchUnit from "./SquadPitchUnit";

const PlayerActions = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0 auto;
  }
`;

const ScoreBoardWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[1]};

  padding-top: ${({ theme }) => theme.space[2]};

  border-radius: 0;
`;

//TODO: Move IListTableProps somewhere more abstract?
export interface IListTableProps {
  elementType: number;
  key: string;
  positions: number[];
  renderElementDialog: (element: IElement) => void;
  renderElementMenu: (position: number) => void;
}

interface IOwnProps {
  listTable: (listTableProps: IListTableProps) => void;
  scoreboard: string;
  showSidebar: () => void;
  submitDialog: (handleHide: () => void) => React.ReactNode;
}

interface IPropsFromState {
  currencyDivisor: number;
  elementsById: IElementsById;
  errors: ISquadErrors;
  lastChange: ILastChange;
  mode: SquadMode;
  nextEvent: IEvent | null;
  proposedElements: IProposedElements;
  savedPicks: ISavedPicks;
  settings: ISettings | null;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  clearLastChange: () => void;
  removeElement: (position: number) => void;
  restoreElement: (position: number) => void;
  showElementDialog: (elementId: number) => void;
  showElementType: (elementTypeId: number) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

interface IState {
  positionForMenu: number;
}

class CreateSquad extends React.Component<Props, IState> {
  public state: IState = {
    positionForMenu: 0,
  };

  public handleShowMenuForElement = (position: number) =>
    this.setState({ positionForMenu: position });

  public handleHideMenuForElement = () => {
    this.setState({ positionForMenu: 0 });
  };

  public removeElement = (position: number) => {
    this.handleHideMenuForElement();
    this.props.removeElement(position);
  };

  public restoreElement = (position: number) => {
    this.handleHideMenuForElement();
    this.props.restoreElement(position);
  };

  public showElementType = (elementType: number) => {
    this.handleHideMenuForElement();
    this.props.showElementType(elementType);
  };

  public showDialog = (element: IElement) => {
    this.props.showElementDialog(element.id);
    this.handleHideMenuForElement();
  };

  public renderMenu() {
    const { t } = this.props;
    let element: IElement | undefined;
    let mode: "remove" | "restore" | undefined;
    if (this.props.proposedElements[this.state.positionForMenu]) {
      element = this.props.proposedElements[this.state.positionForMenu];
      mode = "remove";
    } else if (this.props.savedPicks[this.state.positionForMenu]) {
      element =
        this.props.elementsById[
          this.props.savedPicks[this.state.positionForMenu].element
        ];
      mode = "restore";
    }
    if (!mode || !element) {
      return null;
    }
    return (
      <Dialog closeDialog={this.handleHideMenuForElement}>
        <Dialog.Header closeDialog={this.handleHideMenuForElement}>
          <DialogHeading>
            {`${element.first_name} ${element.second_name}`}
          </DialogHeading>
        </Dialog.Header>
        <Dialog.Body>
          <PlayerActions>
            {mode === "remove" ? (
              <DialogButtonItem>
                <Button
                  variant="primary"
                  onClick={() => this.removeElement(this.state.positionForMenu)}
                  width={1}
                >
                  {t("createSquad.removePlayer", "Remove player")}
                </Button>
              </DialogButtonItem>
            ) : mode === "restore" ? (
              <>
                <DialogButtonItem>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.restoreElement(this.state.positionForMenu)
                    }
                    width={1}
                  >
                    {t("createSquad.restorePlayer", "Restore player")}
                  </Button>
                </DialogButtonItem>
                <DialogButtonItem>
                  <Button
                    variant="primary"
                    onClick={() => this.showElementType(element!.element_type)}
                    width={1}
                  >
                    {t("createSquad.replacePlayer", "Select replacement")}
                  </Button>
                </DialogButtonItem>
              </>
            ) : (
              ""
            )}
            <DialogButtonItem>
              <Button
                variant="primary"
                onClick={() => this.showDialog(element as IElement)}
                width={1}
              >
                {t("createSquad.viewInformation", "View Information")}
              </Button>
            </DialogButtonItem>
          </PlayerActions>
        </Dialog.Body>
      </Dialog>
    );
  }

  public renderElementValueForPosition(pos: number) {
    const element: IElement = this.props.proposedElements[pos];
    const pick: IPick | undefined = this.props.savedPicks[pos];
    // Initial element selection (squad selection)
    if (!pick && element) {
      return integerToMoney(element.now_cost, this.props.currencyDivisor);
    }
    // Removed element (transfers)
    if (pick && !element) {
      return integerToMoney(pick.selling_price, this.props.currencyDivisor);
    }
    // Original element (transfers)
    if (pick && element && pick.element === element.id) {
      return integerToMoney(pick.selling_price, this.props.currencyDivisor);
    }
    // Replaced element (transfers)
    if (pick && element && pick.element !== element.id) {
      return integerToMoney(element.now_cost, this.props.currencyDivisor);
    }
    return null;
  }

  public componentDidMount() {
    this.props.clearLastChange();
  }

  public render() {
    const {
      elementsById,
      errors,
      i18n,
      listTable,
      lastChange,
      mode,
      nextEvent,
      scoreboard,
      settings,
      showSidebar,
      submitDialog,
      t,
      teamsById,
    } = this.props;

    let latestAction: React.ReactNode = null;
    if (lastChange.type === "addition") {
      latestAction = (
        <Alert type="success">
          <Trans i18nKey="createSquad.alert.elementAdded">
            <strong>
              {{ playerName: elementsById[lastChange.element].web_name }}
            </strong>{" "}
            has been added to your squad.
          </Trans>
        </Alert>
      );
    } else if (lastChange.type === "removal") {
      latestAction = (
        <Alert type="success">
          <Trans i18nKey="createSquad.alert.elementRemoved">
            <strong>
              {{ playerName: elementsById[lastChange.element].web_name }}
            </strong>{" "}
            has been removed from your squad.
          </Trans>
        </Alert>
      );
    }

    // Button enablers
    const canEnter = !Boolean(Object.keys(errors).length);

    // Helper functions to generate props used by multiple component instances
    const sharedPitchElementProps = (pos: number) => ({
      pos,
      renderElementMenu: this.handleShowMenuForElement,
      elementValue: this.renderElementValueForPosition(pos),
      showSidebar,
    });

    const sharedSquadListTableProps = (
      pos: number[],
      elementType: number,
      key: string
    ) => ({
      elementType,
      key,
      positions: pos,
      renderElementDialog: this.showDialog,
      renderElementMenu: this.handleShowMenuForElement,
    });

    const submitButtonText = {
      selection: t("createSquad.enterSquad", "Enter Squad"),
      transfers: t("createSquad.makeTransfers", "Make Transfers"),
    };

    const positionsByElementType: {
      [index: string]: number[];
    } = {
      1: [1, 2],
      2: [3, 4, 5, 6, 7],
      3: [8, 9, 10, 11, 12],
      4: [13, 14, 15],
    };

    return (
      <div>
        {settings && (
          <Box mx={2} mb={4}>
            <p>
              {t(
                "createSquad.help",
                "Select a maximum of {{ teamLimit }}  players from a single team or 'Auto Pick' if you're short of time.",
                { teamLimit: settings.squad_team_limit }
              )}
            </p>
          </Box>
        )}
        <ScoreBoardWrapper>
          {nextEvent && (
            <DeadlineBar
              deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
              deadlineLocal={formatRawAsLocalI18n(
                nextEvent.deadline_time,
                dateLocales[i18n.language]
              )}
              headingText={t(
                "createSquad.eventDeadline",
                "{{ eventName }} deadline",
                {
                  eventName: nextEvent.name,
                }
              )}
            />
          )}

          <Scoreboard scoreboard={scoreboard} />
        </ScoreBoardWrapper>
        {errors.overTeamLimit && (
          <AlertWrap>
            <Alert type="error">
              {t(
                "createSquad.errors.overTeamLimit",
                "Too many players selected from"
              )}{" "}
              <strong>
                {errors.overTeamLimit
                  .map((team) => teamsById[team].name)
                  .join(", ")}
              </strong>
            </Alert>
          </AlertWrap>
        )}
        {latestAction && <AlertWrap>{latestAction}</AlertWrap>}

        <Box bg="primary" pt={4} mb={2}>
          <Tabs centered>
            <TabPanel
              label={t("createSquad.pitchView", "Pitch View")}
              link="pitch"
            >
              <Box pt={4}>
                <Pitch>
                  <PitchRow>
                    <PitchUnit />
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(1)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(2)} />
                    </PitchUnit>
                    <PitchUnit />
                  </PitchRow>
                  <PitchRow>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(3)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(4)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(5)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(6)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(7)} />
                    </PitchUnit>
                  </PitchRow>
                  <PitchRow>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(8)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(9)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(10)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(11)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(12)} />
                    </PitchUnit>
                  </PitchRow>
                  <PitchRow>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(13)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(14)} />
                    </PitchUnit>
                    <PitchUnit>
                      <SquadPitchUnit {...sharedPitchElementProps(15)} />
                    </PitchUnit>
                  </PitchRow>
                </Pitch>
              </Box>
            </TabPanel>
            <TabPanel
              label={t("createSquad.listView", "List View")}
              link="pitch"
            >
              <Box mt={4} mb={4} mx="-1px">
                {Object.keys(positionsByElementType).map((et) =>
                  listTable(
                    sharedSquadListTableProps(
                      positionsByElementType[et],
                      parseInt(et, 10),
                      et
                    )
                  )
                )}
              </Box>
            </TabPanel>
          </Tabs>
        </Box>

        {/* <ShirtSoonAlert /> */}
        {this.renderMenu()}
        <DialogManager
          render={(showDialog, handleShow, handleHide) => (
            <>
              <SaveBar>
                <Button
                  variant="primary"
                  onClick={handleShow}
                  disabled={!canEnter}
                  width={1}
                >
                  {submitButtonText[mode]}
                </Button>
              </SaveBar>
              {showDialog && submitDialog(handleHide)}
            </>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  currencyDivisor: 10,
  elementsById: getElementsById(state),
  errors: getErrors(state),
  lastChange: getLastChange(state),
  mode: getSquadMode(state),
  nextEvent: getNextEvent(state),
  proposedElements: getProposedElements(state),
  savedPicks: getSavedPicks(state),
  settings: getSettings(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: IOwnProps
): IPropsFromDispatch => ({
  clearLastChange: () => dispatch(resetLastChange()),
  removeElement: (position) => dispatch(removeElement(position)),
  restoreElement: (position) => dispatch(restoreElement(position)),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  showElementType: (elementTypeId) => {
    dispatch(updateElementTypeControl(elementTypeId));
    ownProps.showSidebar();
  },
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateSquad)
);

import React from "react";
import styled from "styled-components/macro";
import Copy from "./Copy";

const StyledCollapsible = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  > h5 {
    margin: 0;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.grey};
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: 1.4rem;
  text-align: start;

  :hover {
    font-weight: bold;
  }

  svg {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  &[aria-expanded="true"] {
    svg {
      transform: rotate(180deg);
      stroke: ${({ theme }) => theme.colors.white};
    }
    background-image: ${({ theme }) => theme.colors.pinkGradient};
    padding: 9px;

    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const CollapseIcon = styled.div`
  width: 14px;
  margin-inline-end: 10px;
  flex-shrink: 0;
`;

const CollapsibleContent = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.borders[0]};

  &[aria-hidden] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
`;

// Used to generate unique ids for collapsible content / aria controls
let lastId = 0;
const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;

interface IProps {
  headingText: string;
}

interface IState {
  open: boolean;
}

class Collapsible extends React.Component<IProps, IState> {
  public state = { open: false };
  public id = "";
  public toggle = () => this.setState({ open: !this.state.open });

  componentDidMount() {
    this.id = newId("ism-collapsible-");
  }

  render() {
    const { children, headingText } = this.props;
    return (
      <StyledCollapsible>
        <h5>
          <Button
            type="button"
            onClick={this.toggle}
            aria-expanded={this.state.open}
            aria-controls={this.id}
          >
            <CollapseIcon>
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1L7.15789 8L1 0.999999"
                  stroke-width="2"
                  stroke-linecap="round"
                  fill="none"
                />
              </svg>
            </CollapseIcon>
            {headingText}
          </Button>
        </h5>
        <CollapsibleContent id={this.id} aria-hidden={!this.state.open}>
          <Copy>{children}</Copy>
        </CollapsibleContent>
      </StyledCollapsible>
    );
  }
}

export default Collapsible;

import { size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as ArrowLeftDoubleIcon } from "../../img/icons/arrow-double-left.svg";
import { ReactComponent as ArrowRightDoubleIcon } from "../../img/icons/arrow-double-right.svg";
import { ReactComponent as ArrowHyphenIcon } from "../../img/icons/arrow-hyphen.svg";
import { ReactComponent as ArrowLeftIcon } from "../../img/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../img/icons/arrow-right.svg";

interface IArrow {
  color?: string;
}

export const ArrowLeft = styled(ArrowLeftIcon)<IArrow>`
  color: ${(props: any) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
  fill: none;
`;

export const ArrowRight = styled(ArrowRightIcon)<IArrow>`
  color: ${(props: any) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
  fill: none;
`;

export const ArrowLeftDouble = styled(ArrowLeftDoubleIcon)<IArrow>`
  color: ${(props: any) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
  fill: none;
`;

export const ArrowRightDouble = styled(ArrowRightDoubleIcon)<IArrow>`
  color: ${(props: any) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
  fill: none;
`;

export const ControlArrowRight = styled(ArrowRight)`
  ${size("16px")};
  margin-inline-start: 0.5rem;
  vertical-align: middle;
  color: ${(props: any) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
  fill: none;
`;

export const ControlArrowLeft = styled(ArrowLeft)`
  ${size("16px")};
  margin-inline-end: 0.5rem;
  vertical-align: middle;
  color: ${(props: any) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
  fill: none;
`;

export const ArrowHyphen = styled(ArrowHyphenIcon)`
  vertical-align: middle;
  color: ${(props: any) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
  fill: none;
`;

import React from "react";
import styled from "styled-components/macro";

const StyledDeadlineBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]};
  border-radius: 0;
  background: ${({ theme }) => theme.colors.pinkGradient};
  font-size: 1.8rem;
`;

const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
  font-size: inherit;
`;

const Deadline = styled.time`
  margin-inline-start: 0.4rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`;

interface IProps {
  deadlineISO: string;
  deadlineLocal: string;
  headingText: string;
}

const DeadlineBar: React.FC<IProps> = ({
  deadlineISO,
  deadlineLocal,
  headingText,
}) => (
  <StyledDeadlineBar>
    <Heading>{headingText}:</Heading>
    <span>&nbsp;</span>
    <Deadline dateTime={deadlineISO}>{deadlineLocal}</Deadline>
  </StyledDeadlineBar>
);

export default DeadlineBar;

import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import Title from "../Title";
import EventInfo from "./EventInfo";
import EventStatusBar from "./EventStatusBar";
import PlayerAvailability from "./PlayerAvailability";
import StatusDreamTeam from "./StatusDreamTeam";
import TransfersPanel from "./TransfersPanel";
import { ReactComponent as TitleIcon } from "../../img/icons/title-icon.svg";
import TitleContainer from "../TitleIconContainer";

const StatusWrapper = styled.div`
  max-width: 1220px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-inline-end: ${({ theme }) => theme.space[2]};
    padding-inline-start: ${({ theme }) => theme.space[2]};
  }
`;

const StatusCols = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StatusMain = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 66.66%;
  }
`;

const StatusSecondary = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 30%;
  }
`;
const Heading = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}

const Status: React.FC<IPropsFromState> = ({ now, player }) => {
  const { t } = useTranslation();

  if (!now || !player) {
    return null;
  }

  return (
    <StatusWrapper>
      <Heading>
        <p>
          {t("status.loggedIn", "You are logged in as {{ name }}", {
            name: `${player.first_name} ${player.last_name}`,
          })}
        </p>
        <TitleContainer $hasMargin={false}>
          <TitleIcon />
          <Title>
            {t("status.title", " {{ name }} Status ", {
              name: now.name,
            })}
          </Title>
        </TitleContainer>
      </Heading>
      <Box mb={4}>
        <EventStatusBar />
      </Box>
      <Box mb={4}>
        <EventInfo />
      </Box>
      <Box mb={4}>
        <StatusDreamTeam />
      </Box>

      <StatusCols>
        <StatusMain>
          <Box mb={4}>
            <TransfersPanel />
          </Box>
          <Box mb={4}>
            <TransfersPanel isOut={true} />
          </Box>
        </StatusMain>
        <StatusSecondary>
          <Box mb={4}>
            <PlayerAvailability />
          </Box>
        </StatusSecondary>
      </StatusCols>
    </StatusWrapper>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default connect(mapStateToProps)(Status);

import { navigate } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import useFormHook from "../core/hooks/useFormHook";
import { RootState, ThunkDispatch } from "../core/store";
import { getEntry } from "../core/store/entries/reducers";
import { fetchEntrySummary, updateEntry } from "../core/store/entries/thunks";
import { IEntry, IUpdateEntryData } from "../core/store/entries/types";
import { getEvents } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData } from "../core/store/player/reducers";
import { ILoggedInPlayer, IPlayer } from "../core/store/player/types";
import { getTeams } from "../core/store/teams/reducers";
import { ITeam } from "../core/store/teams/types";
import { formatRawAsLocalI18n } from "../core/utils/datetime";
import { dateLocales } from "../i18n";
import Button from "./Button";
import {
  CheckboxField,
  FieldWrap,
  InputField,
  SelectField,
} from "./FieldRenderers";
import { Main, Wrapper } from "./Layout";
import SubHeading from "./SubHeading";
import Title from "./Title";

const StyledTitle = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes[7]};
`;

export type ShirtValues = "plain" | "stripes" | "hoops";

export type SockValues = "plain" | "hoops";

export type SponsorValues =
  | "none"
  | "pl-kicks"
  | "pl-primary-stars"
  | "rainbow-laces"
  | "sky-ocean-rescue"
  | "no-racism";

type KitState = {
  "ism-sponsor-logo": SponsorValues;
  "ism-shirt-style": ShirtValues;
  "ism-sock-style": SockValues;
  "ism-shirt-color": string;
  "ism-shirt-style-color": string;
  "ism-sleeve-color": string;
  "ism-short-color": string;
  "ism-sock-color": string;
  "ism-sock-style-color": string;
};

export type FormState = KitState & {
  "ism-team-name": string;
  "ism-fav-club": number;
  "ism-email": boolean;
  "ism-language": string;
};

export const initState = (entry: IEntry, player: IPlayer): FormState => ({
  "ism-shirt-style": "plain",
  "ism-sponsor-logo": "none",
  "ism-sock-style": "plain",
  "ism-shirt-color": "#E1E1E1",
  "ism-sleeve-color": "#E1E1E1",
  "ism-short-color": "#E1E1E1",
  "ism-sock-color": "#E1E1E1",
  "ism-shirt-style-color": "#E1E1E1",
  "ism-sock-style-color": "#E1E1E1",
  "ism-team-name": entry.name,
  "ism-fav-club": entry.favourite_team || -1,
  "ism-email": player.entry_email ? true : false,
  "ism-language": player.entry_language || "",
});

type Props = {
  entry: IEntry;
  player: IPlayer;
  events: IEvent[];
  teams: ITeam[];
  updateEntry: (data: IUpdateEntryData) => void;
};

const EntryUpdate: React.FunctionComponent<Props> = ({
  entry,
  events,
  player,
  teams,
  updateEntry,
}) => {
  const [
    formState,
    updateState,
    updateCheckboxState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateColorState,
    updateSelectState,
  ] = useFormHook<FormState>(initState(entry, player));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fave = Number(formState["ism-fav-club"]);
    updateEntry({
      name: formState["ism-team-name"],
      kit: "",
      favourite_team: fave === -1 ? null : fave,
      email: formState["ism-email"],
      language: formState["ism-language"],
    });
  };

  // filterTeams
  const damallsvenskan = teams
    .filter((team) => team.unavailable === false)
    .sort((a, b) => a.name.localeCompare(b.name));
  // First Division
  const elitettan = teams
    .filter((team) => team.unavailable === true)
    .sort((a, b) => a.name.localeCompare(b.name));

  const { i18n, t } = useTranslation();
  const languages: { [key: string]: string } = {
    en: t("entryUpdate.languageEn", "English"),
    ar: t("entryUpdate.languageSv", "Swedish"),
  };
  return (
    <Wrapper>
      <Main>
        <Box mx={2}>
          <form onSubmit={handleSubmit}>
            <Box mb={4}>
              <StyledTitle>
                {t("entryUpdate.myDetails", "My Details")}
              </StyledTitle>
            </Box>
            <Box mb={4}>
              <SubHeading>
                {t("entryUpdate.teamDetails", "Team Details")}
              </SubHeading>
            </Box>
            <FieldWrap>
              <InputField
                id="ism-team-name"
                label="Team Name"
                onChange={updateState}
                hint={t("entryUpdate.maxCharacters", "Maximum 20 characters")}
                value={formState["ism-team-name"]}
                maxLength={20}
              />
            </FieldWrap>
            <Box mb={4}>
              <p>
                {t(
                  "entryUpdate.p1",
                  "Please think carefully before entering your team name. Teams with names that are deemed inappropriate or offensive may result in your account being deleted. Please refer to the Terms & Conditions of entry for more information."
                )}
              </p>
            </Box>
            <Box mb={4}>
              <SubHeading>
                {t("entryUpdate.favouriteClubHeading", "Favourite Club")}
              </SubHeading>
            </Box>
            <FieldWrap>
              <SelectField
                id="ism-fav-club"
                label={t("entryUpdate.favouriteClub", "Favourite club")}
                onChange={updateSelectState}
                value={formState["ism-fav-club"]}
              >
                <option
                  value={0}
                  aria-selected={formState["ism-fav-club"] === 0}
                ></option>

                <optgroup label={t("topLeague", "Damallsvenskan")}>
                  {damallsvenskan.map((tm) => (
                    <option
                      value={tm.id}
                      key={tm.id}
                      aria-selected={formState["ism-fav-club"] === tm.id}
                    >
                      {tm.name}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={t("firstDivision", "Elitettan")}>
                  {elitettan.map((tm) => (
                    <option
                      value={tm.id}
                      key={tm.id}
                      aria-selected={formState["ism-fav-club"] === tm.id}
                    >
                      {tm.name}
                    </option>
                  ))}
                </optgroup>
                <option
                  value={-1}
                  aria-selected={formState["ism-fav-club"] === -1}
                >
                  {t("createEntryDialog.none", "None")}
                </option>
              </SelectField>
            </FieldWrap>
            <Box mb={4}>
              <p>
                {t(
                  "entryUpdate.p2",
                  "Choose your favourite Damallsvenskan club if you would like to be entered into a supporters league. If you do not select an option before {{ firstRoundDeadline }}, you will not be entered into a supporters league.",
                  {
                    firstRoundDeadline: formatRawAsLocalI18n(
                      events[0].deadline_time,
                      dateLocales[i18n.language]
                    ),
                  }
                )}
              </p>
            </Box>
            <SubHeading>
              {t(
                "entryUpdate.newsletter.title",
                "Subscribe To Email Newsletters"
              )}
            </SubHeading>
            <FieldWrap>
              <CheckboxField
                id="ism-email"
                label={t(
                  "entryUpdate.newsletter.label",
                  "I would like to receive updates about my fantasy team via email."
                )}
                onChange={updateCheckboxState}
                checked={formState["ism-email"]}
              />
            </FieldWrap>
            <FieldWrap>
              <SelectField
                id="ism-language"
                label={t(
                  "entryUpdate.newsletter.language",
                  "Language of the fantasy team email"
                )}
                onChange={updateSelectState}
                value={formState["ism-language"]}
              >
                {Object.keys(languages).map((key, index) => (
                  <option
                    value={key}
                    key={index}
                    aria-selected={formState["ism-language"] === key}
                  >
                    {languages[key]}
                  </option>
                ))}
              </SelectField>
            </FieldWrap>
            <Box mb={3}>
              <Button type="submit">
                {t("entryUpdate.updateDetails", "Update Details")}
              </Button>
            </Box>
          </form>
        </Box>
      </Main>
    </Wrapper>
  );
};

export { EntryUpdate as EntryUpdateTest };

interface IPropsFromState {
  entry: IEntry | null;
  events: IEvent[];
  player: ILoggedInPlayer;
  teams: ITeam[];
}

interface IPropsFromDispatch {
  fetchEntry: (entryId: number) => void;
  updateEntry: (data: IUpdateEntryData) => void;
}

type FetcherProps = IPropsFromState & IPropsFromDispatch;

class EntryUpdateFetcher extends React.Component<FetcherProps> {
  public componentDidMount() {
    this.props.fetchEntry(Number(this.props.player.entry));
  }
  public render() {
    return this.props.entry ? (
      <EntryUpdate
        player={this.props.player}
        entry={this.props.entry}
        events={this.props.events}
        teams={this.props.teams}
        updateEntry={this.props.updateEntry}
      />
    ) : null;
  }
}

const mapStateToProps = (state: RootState) => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  return {
    player,
    entry: getEntry(state, player.entry),
    events: getEvents(state),
    teams: getTeams(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntry: (entryId: number) => dispatch(fetchEntrySummary(entryId)),
  updateEntry: async (data: IUpdateEntryData) => {
    await dispatch(updateEntry(data))
      .then(() => navigate("/my-team"))
      .catch(() => {});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EntryUpdateFetcher);

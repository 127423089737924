import { size } from "polished";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ReactComponent as FooterClientLogo } from "../img/alldam-logo.svg";
import { ReactComponent as FooterPartnerLogo } from "../img/svenska-spel.svg";

const StyledFooterWrapper = styled.div`
  background-color: white;
`;

const StyledFooter = styled.footer`
  padding-top: ${({ theme }) => theme.space[6]};
  padding-bottom: ${({ theme }) => theme.space[0]};
  background-color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes[2]};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space[6]};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]};
  margin: ${({ theme }) => theme.space[6]};
`;

const FooterHeading = styled.h4`
  margin: 0;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes[5]};
  text-transform: uppercase;
`;

const LinkHeading = styled.li`
  margin: 0;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;

const LinkContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: ${({ theme }) => theme.space[2]};
  padding: 0;
  list-style-type: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
`;

const ListItemLink = styled.a`
  color: white;
  line-height: 1;
  text-decoration: none;
  font-weight: bold;
`;

const ClientLogo = styled(FooterClientLogo)`
  ${size(70)}
`;

const PartnerLogo = styled(FooterPartnerLogo)`
  ${size(46, 160)}
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <StyledFooterWrapper>
      <FlexContainer>
        <FooterHeading>{t("footer.mainSponsor", "Main Sponsor")}</FooterHeading>
        <PartnerLogo />
      </FlexContainer>
      <StyledFooter>
        <Inner>
          <LinkContainer>
            <LinkHeading>{t("footer.links.heading", "Links")}</LinkHeading>
            <li>
              <ListItemLink
                href="https://www.obosdamallsvenskan.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                OBOS Damallsvenskan
              </ListItemLink>
            </li>
            <li>
              <ListItemLink
                href="https://efd.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                Elitfotboll Dam
              </ListItemLink>
            </li>
          </LinkContainer>

          <FlexContainer>
            <ClientLogo />
          </FlexContainer>
        </Inner>
      </StyledFooter>
    </StyledFooterWrapper>
  );
};

export default Footer;

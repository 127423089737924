import { Link } from "@reach/router";
import React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSquadError } from "../../core/store/squad/reducers";
import { createEntry } from "../../core/store/squad/thunks";
import { ICreateEntryData } from "../../core/store/squad/types";
import { getTeams } from "../../core/store/teams/reducers";
import { ITeam } from "../../core/store/teams/types";
import Alert from "../Alert";
import Button from "../Button";
import Dialog from "../Dialog";
import DialogHeading from "../DialogHeading";
import {
  CheckboxField,
  FieldWrap,
  InputField,
  SelectField,
} from "../FieldRenderers";
import { getCodeFromError } from "./helpers";

interface IProps {
  handleHide: () => void;
}

interface IPropsFromState {
  error: any;
  nextEvent: IEvent | null;
  teams: ITeam[];
}

interface IPropsFromDispatch {
  createEntry: (data: ICreateEntryData) => void;
}

type Props = IProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

interface IState extends ICreateEntryData {
  favourite_team: number;
}

class CreateEntryDialog extends React.Component<Props, IState> {
  public state: IState = {
    email: false,
    favourite_team: 0,
    name: "",
    terms_agreed: false,
    language: this.props.i18n.language,
  };

  public requiredFields: Array<keyof IState> = [
    "favourite_team",
    "name",
    "terms_agreed",
    "language",
  ];

  public apiDataFromState = () => ({
    email: this.state.email,
    favourite_team:
      this.state.favourite_team > 0 ? this.state.favourite_team : null,
    name: this.state.name,
    terms_agreed: this.state.terms_agreed,
    language: this.props.i18n.language,
  });

  public handleEmailChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ email: e.currentTarget.checked ? true : false });

  public handleFaveChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ favourite_team: parseInt(e.currentTarget.value, 10) });

  public handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ name: e.currentTarget.value });

  public handleTermsChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ terms_agreed: e.currentTarget.checked ? true : false });

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.isValid()) {
      this.props.createEntry(this.apiDataFromState());
    }
  };

  public isValid = () =>
    this.requiredFields.every((e) => Boolean(this.state[e]));

  public render() {
    const { handleHide, nextEvent, error, teams, t } = this.props;
    // filterTeams
    const damallsvenskan = teams
      .filter((team) => team.unavailable === false)
      .sort((a, b) => a.name.localeCompare(b.name));
    // First Division
    const elitettan = teams
      .filter((team) => team.unavailable === true)
      .sort((a, b) => a.name.localeCompare(b.name));

    const errorCode = getCodeFromError(error);
    if (error) {
      const handledErrorCodes = ["price_changed"];
      if (handledErrorCodes.indexOf(errorCode) === -1) {
        window.location.reload();
        return null;
      }
    }
    if (!nextEvent) {
      return (
        <Dialog closeDialog={handleHide}>
          <Dialog.Header closeDialog={handleHide}>
            {t("createEntryDialog.over", "Game over")}
          </Dialog.Header>
          <Dialog.Body isPadded={true}>
            <p>
              {t(
                "createEntryDialog.finished",
                "This game has now finished. Thanks for your interest, come back soon to select your squad for next season's game."
              )}
            </p>
          </Dialog.Body>
        </Dialog>
      );
    }
    return (
      <Dialog closeDialog={handleHide}>
        <Dialog.Header closeDialog={handleHide}>
          <DialogHeading>
            {t("createEntryDialog.enterSquad", "Enter squad")}
          </DialogHeading>
        </Dialog.Header>

        <Dialog.Body isPadded={true}>
          <form onSubmit={this.handleSubmit}>
            <FieldWrap>
              <InputField
                id="entryName"
                name="entryName"
                label={t("createEntryDialog.teamName", "Team Name")}
                hint={t(
                  "createEntryDialog.maxCharacters",
                  "Maximum 20 characters"
                )}
                onChange={this.handleNameChange}
                value={this.state.name}
                maxLength={20}
              />
            </FieldWrap>
            <FieldWrap>
              <SelectField
                id="entryFave"
                name="entryFave"
                label={t("createEntryDialog.favouriteClub", "Favourite Club")}
                onChange={this.handleFaveChange}
                value={this.state.favourite_team}
              >
                <option
                  value={0}
                  aria-selected={this.state.favourite_team === 0}
                >
                  {t("createEntryDialog.chooseAClub", "Choose a club")}...
                </option>
                <optgroup label={t("topLeague", "Damallsvenskan")}>
                  {damallsvenskan.map((tm) => (
                    <option
                      value={tm.id}
                      key={tm.id}
                      aria-selected={this.state.favourite_team === tm.id}
                    >
                      {tm.name}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={t("firstDivision", "Elitettan")}>
                  {elitettan.map((tm) => (
                    <option
                      value={tm.id}
                      key={tm.id}
                      aria-selected={this.state.favourite_team === tm.id}
                    >
                      {tm.name}
                    </option>
                  ))}
                </optgroup>

                <option
                  value={-1}
                  aria-selected={this.state.favourite_team === -1}
                >
                  {t("createEntryDialog.none", "None")}
                </option>
              </SelectField>
            </FieldWrap>
            <FieldWrap>
              <CheckboxField
                id="entryEmail"
                name="entryEmail"
                label={t(
                  "createEntryDialog.text",
                  "I'd like to get email updates about my fantasy team."
                )}
                onChange={this.handleEmailChange}
                checked={this.state.email}
              />
            </FieldWrap>
            <FieldWrap>
              <CheckboxField
                id="entryTerms"
                name="entryTerms"
                label={
                  <Trans i18nKey="createEntryDialog.terms">
                    I agree to the
                    <Link to="/help/terms">Terms and conditions</Link>
                  </Trans>
                }
                onChange={this.handleTermsChange}
                checked={this.state.terms_agreed}
              />
            </FieldWrap>
            {errorCode && (
              <Box mb={2}>
                <Alert type="error">
                  {errorCode === "price_changed" &&
                    t(
                      "createEntryDialog.unable",
                      "Unable to enter squad as player prices have changed. Please refresh the page and try again."
                    )}
                </Alert>
              </Box>
            )}
            <Button type="submit" disabled={!this.isValid()}>
              {t("createEntryDialog.enterSquad", "Enter squad")}
            </Button>
          </form>
        </Dialog.Body>
      </Dialog>
    );
  }
}

export { CreateEntryDialog as CreateEntryDialogTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  error: getSquadError(state),
  nextEvent: getNextEvent(state),
  teams: getTeams(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  createEntry: (data) => dispatch(createEntry(data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateEntryDialog)
);

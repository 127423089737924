import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import configureStore from "./core/configureStore";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://ab17a947e86aa674df3fdb1fbd8457a3@o118622.ingest.us.sentry.io/4507017186770944",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
    ],

    maxBreadcrumbs: 50,

    tracesSampleRate: 0.000002,
    profilesSampleRate: 0.000002,
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from "react";
import styled from "styled-components/macro";
import EntryTab from "./EntryTab";
import { ITabPanel } from "./TabPanel";
import { TabListShared } from "./Tabs";

const TabList = styled.ul`
  ${TabListShared}
  padding: 2px;
  border: ${({ theme }) => theme.borders[2]};
`;

interface IProps {
  children: Array<React.ReactElement<ITabPanel>>;
}

const EntryTabs: React.FC<IProps> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleClick = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <div>
      <TabList>
        {React.Children.map(children, (child, index) => {
          const panel = child;
          return (
            <EntryTab
              key={index}
              index={index}
              label={panel.props.label}
              link={panel.props.link}
              onClick={handleClick}
              selected={selectedTab === index}
            />
          );
        })}
      </TabList>
      {children[selectedTab]}
    </div>
  );
};

export default EntryTabs;

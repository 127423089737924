import { Link } from "@reach/router";
import { ellipsis } from "polished";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry } from "../../core/store/entries/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { ControlArrowRight } from "../icons/Arrows";

const StyledEventInfo = styled.div`
  margin: ${({ theme }) => theme.space[4]} 0;
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: ${({ theme }) => theme.space[6]};
  }
`;

const EventScores = styled.ul`
  display: flex;
  padding: 0;
  gap: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    justify-content: space-around;
    gap: ${({ theme }) => theme.space[3]};
  }
`;

const EventScore = styled.li`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[3]};
  gap: ${({ theme }) => theme.space[2]};
  background: ${({ theme }) => theme.colors.primaryGradient};
  list-style-type: none;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1 0 30%;
  }
`;

const ItemHeading = styled.h4`
  margin: 0;
`;

const ItemValue = styled.div`
  ${ellipsis()};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: bold;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 2.5rem;
  }
`;

const EventStats = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]};
  padding: 0;
  list-style-type: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
    justify-content: space-around;
    gap: ${({ theme }) => theme.space[3]};
  }
`;

const EventStat = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[3]};
  gap: ${({ theme }) => theme.space[2]};
  background: ${({ theme }) => theme.colors.pinkGradient};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1 0 30%;
  }
`;

const ArrowLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  entry: IEntry | null;
  now: IEvent | null;
  player: IPlayer;
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

const EventInfo: React.FC<Props> = ({ elementsById, entry, now, player }) => {
  const { t } = useTranslation();

  if (!now || !entry) {
    return null;
  }
  const matches = now.chip_plays.filter((cp) => cp.chip_name === "wildcard");
  const wildcards = matches.length ? matches[0].num_played : "-";
  return (
    <StyledEventInfo>
      <EventScores>
        <EventScore>
          <ItemHeading>
            {t("eventInfo.averageScore", "Average score")}
          </ItemHeading>
          <ItemValue>{now.average_entry_score || "-"}</ItemValue>
        </EventScore>
        {entry.started_event <= now.id && (
          <EventScore>
            <ItemHeading>{t("eventInfo.yourScore", "Your score")}</ItemHeading>
            <ItemValue>
              <ArrowLink to={`/entry/${player.entry}/event/${now.id}`}>
                {entry.summary_event_points}
                <ControlArrowRight />
              </ArrowLink>
            </ItemValue>
          </EventScore>
        )}
        <EventScore>
          <ItemHeading>
            {t("eventInfo.highestScore", "Highest score")}
          </ItemHeading>
          <ItemValue>
            {now.highest_scoring_entry ? (
              <ArrowLink
                to={`/entry/${now.highest_scoring_entry}/event/${now.id}/`}
              >
                {now.highest_score}
                <ControlArrowRight />
              </ArrowLink>
            ) : (
              "-"
            )}
          </ItemValue>
        </EventScore>
      </EventScores>
      <EventStats>
        {now.id > 1 && (
          <EventStat>
            <ItemHeading>
              {t("eventInfo.transfersMade", "Transfers Made")}
            </ItemHeading>
            <ItemValue>
              {now.transfers_made ? now.transfers_made.toLocaleString() : "-"}
            </ItemValue>
          </EventStat>
        )}
        <EventStat>
          <ItemHeading>
            {t("eventInfo.wildcardsPlayed", "Wildcards Played")}
          </ItemHeading>
          <ItemValue>
            {wildcards === null ? "-" : wildcards.toLocaleString()}
          </ItemValue>
        </EventStat>
        <EventStat>
          <ItemHeading>
            {t("eventInfo.mostCaptained", "Most Captained")}
          </ItemHeading>
          <ItemValue>
            {now.most_captained
              ? elementsById[now.most_captained].web_name
              : "-"}
          </ItemValue>
        </EventStat>
      </EventStats>
    </StyledEventInfo>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as IPlayer;
  const entryId = player.entry || 0;
  const now = getCurrentEvent(state) as IEvent;
  return {
    elementsById: getElementsById(state),
    entry: getEntry(state, entryId),
    now,
    player,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);

// my-theme.ts
import { em } from "polished";
import { DefaultTheme } from "styled-components";

const black = "#242424";
const blue = "#0050ff";
const darkPink = "#db3069";
const darkPinkDarker = "#c12A5c";
const fantasy = "#00ff87";
const greyLight = "#d8d8d8";
const lightBlue = "#30405f";
const primary = "#182a4c";
const primaryDarker = "#09142f";
const yellow = "#ffd600";
const whiteGrey = "#ededed";
const green = "#5dda78";
const primaryGradient =
  "linear-gradient(90deg, #182a4c 59.87%, rgba(21, 46, 93, 0.30) 100%)";

const pinkGradient =
  "linear-gradient(90deg, #db3069 0%, #e13a99 79.5%, rgba(230, 67, 201, 0.30) 99%)";

const theme: DefaultTheme = {
  borders: [
    `1px solid ${greyLight}`,
    `1px solid ${whiteGrey}`,
    `1px solid ${primary}`,
    `2px solid ${black}`,
    `6px solid ${primary}`,
  ],
  breakpoints: [
    em("400px"),
    em("610px"),
    em("700px"),
    em("900px"),
    em("1024px"),
    em("1220px"),
  ],
  colors: {
    black,
    blue,
    blueDark: "#0037af",
    darkPink,
    difficulties: {
      1: {
        bg: "#257d5a",
        color: "white",
      },
      2: {
        bg: "#00ff86",
        color: "black",
      },
      3: {
        bg: "#ebebe4",
        color: "black",
      },
      4: {
        bg: "#ff005a",
        color: "white",
      },
      5: {
        bg: "#861d46",
        color: "white",
      },
    },
    elementStatus: {
      0: {
        bg: "#c0020d",
        color: "white",
      },
      25: {
        bg: "#d44401",
        color: "white",
      },
      50: {
        bg: "#ffab1b",
        color: black,
      },
      75: {
        bg: "#ffe65b",
        color: black,
      },
    },
    elementTypes: {
      1: {
        bg: yellow,
        color: primary,
      },
      2: {
        bg: fantasy,
        color: primary,
      },
      3: {
        bg: blue,
        color: primary,
      },
      4: {
        bg: darkPink,
        color: "white",
      },
    },
    error: "#e02200",
    fantasy,
    green,
    greenDark: "#93e400",
    lightBlue,
    grey: "#e7e7e7",
    greyDark: "#535353",
    greyLight,
    greyLight2: "#c9ced6",
    greyLight3: "#f7f7f7",
    pink: "#ff2882",
    primary,
    primaryDarker,
    primaryGradient,
    pinkGradient,
    darkPinkDarker,
    greyDarker1: "#e5e5e5",
    greyDarker2: "#e0e0e0",
    greyDarker3: "#6E6E6E",
    blueDarker1: "#3069a5",
    blueDarker2: "#004986",
    blueDarker3: "#14253c",
    blueDarker4: "#161619",
    secondary: lightBlue,
    social: {
      facebook: {
        bg: "#3b5998",
        color: "white",
      },
      twitter: {
        bg: "#00aced",
        color: "white",
      },
      google: {
        bg: "white",
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    success: "#5dda78",
    tertiary: "#d2eff9",
    text: black,
    white: "#fff",
    whiteBlue: "#f2f9fa",
    whiteGrey,
    yellow,
    yellowLight: "#fff59c",
  },
  fontSizes: [
    "1.2rem",
    "1.3rem",
    "1.4rem",
    "1.6rem",
    "1.8rem",
    "2rem",
    "2.4rem",
    "2.6rem",
  ],
  fonts: {
    base: '"Mulish Medium", Helvetica, Arial, sans-serif',
    raleway: '"Raleway", Helvetica, Arial, sans-serif',
    light: '"Mulish light", Helvetica, Arial, sans-serif',
    bold: '"Mulish bold", Helvetica, Arial, sans-serif',
    book: '"Mulish book", Helvetica, Arial, sans-serif',
  },
  radii: ["2px", "5px", "10px", "50px"],
  space: ["0", "0.5rem", "1rem", "1.5rem", "2rem", "2.5rem", "3rem"],
  zIndex: {
    clientMenu: 7000,
    dialog: 9000,
    menu: 6000,
    overlay: 8000,
    select: {
      select: 50,
      wrap: 40,
    },
  },
};

export default theme;

import styled from "styled-components/macro";

export const Pager = styled.div`
  display: flex;
`;

export const EventPager = styled.div`
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: -4.5rem;
  }
`;

export const PagerItem = styled.div`
  flex: 1;
  padding: 0 ${(props) => props.theme.space[2]};
`;

export const PagerButton = styled.div`
  flex-basis: 50%;
  padding: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 33%;
  }
`;

export const PagerButtonNext = styled(PagerButton)`
  margin-left: auto;
`;

export const PagerHeading = styled.h3`
  margin: ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    text-align: center;
    margin: 0 33%;
    padding: 1.1rem 1rem;
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

export const PagerItemNext = styled(PagerItem)`
  margin-left: auto;
  text-align: end;
`;

import React from "react";
import styled, { css } from "styled-components/macro";

const TabHeadingOuter = styled.div<ITabHeadingOuter>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  ${(props) =>
    props.isCentered &&
    css`
      text-align: center;
    `}
`;

const TabHeadingInner = styled.div<ITabHeadingInner>`
  display: inline-block;
  padding: 0 ${({ theme }) => theme.space[1]};
  color: ${({ theme }) => theme.colors.black};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${(props) =>
      props.isBlock &&
      css`
        display: block;
      `}
  }
`;

const TabHeadingTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;

interface IOwnProps {
  title: React.ReactNode;
}

interface ITabHeadingOuter {
  isCentered?: boolean;
}

interface ITabHeadingInner {
  isBlock?: boolean;
}

type Props = IOwnProps & ITabHeadingOuter & ITabHeadingInner;

const TabHeading: React.FC<Props> = ({
  isBlock = false,
  isCentered = false,
  title,
}) => (
  <TabHeadingOuter isCentered={isCentered}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="26.5"
      viewBox="0 0 6 25"
    >
      <rect width="6" height="26.5" />
    </svg>
    <TabHeadingInner isBlock={isBlock}>
      <TabHeadingTitle>{title}</TabHeadingTitle>
    </TabHeadingInner>
  </TabHeadingOuter>
);

export default TabHeading;

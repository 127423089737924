import { hiDPI, size } from "polished";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";

import HeaderLogo1x from "../img/logo-header@1x.png";
import HeaderLogo2x from "../img/logo-header@2x.png";
import { ReactComponent as GBFlag } from "../img/flags/gb.svg";
import { ReactComponent as SEFlag } from "../img/flags/se.svg";
import headerPatternBg2x from "../img/header-pattern-bg@2x.png";
import headerPatternBg1x from "../img/header-pattern-bg.png";
import headerPatternSm2x from "../img/header-pattern-sm@2x.png";
import headerPatternSm1x from "../img/header-pattern-sm.png";
import playerComposite2x from "../img/player-comp@2x.png";
import playerComposite1x from "../img/player-comp@1x.png";
import { getIsWebView } from "../utils/webView";
import Navigation from "./nav/Navigation";
import { Wrapper } from "./Layout";

const GameHeaderOuter = styled.div`
  min-height: 200px;

  background-image: url(${headerPatternSm1x});

  ${hiDPI(2)} {
    background-image: url(${headerPatternSm2x});
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 34%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    background-image: url(${headerPatternBg1x});
    ${hiDPI(2)} {
      background-image: url(${headerPatternBg2x});
    }
  }
`;

const GameHeaderInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space[2]};
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => theme.space[3]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${({ theme }) =>
      `${theme.space[3]} ${theme.space[4]} ${theme.space[4]}`};
  }
`;

const AssetContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 70px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-basis: 85px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-basis: 105px;
  }
`;

const HeaderLogo = styled.div`
  background-image: url(${HeaderLogo1x});
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 100%;

  ${hiDPI(2)} {
    background-image: url(${HeaderLogo2x});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    height: 70px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    height: 90px;
  }
`;

const PlayerImage = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: absolute;
    bottom: 0;
    background-image: url(${playerComposite1x});
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
    background-position: 85% 0;

    ${hiDPI(2)} {
      background-image: url(${playerComposite2x});
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 190px;
  }
`;

const LogoFlagWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FlagList = styled.ul`
  top: 15px;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 1;
`;

const FlagItem = styled.li`
  display: inherit;
  vertical-align: middle;
  line-height: 1;

  :not(:last-child) {
    padding-right: 1rem;
    padding-bottom: 0;
    border-right: 1px solid white;
    border-bottom: 0;
  }
`;

const FlagLink = styled.a`
  display: inherit;
`;

const FlagStyles = css`
  ${size(17, 28)}
`;

const StyledSEFlag = styled(SEFlag)`
  ${FlagStyles}
`;

const StyledGBFlag = styled(GBFlag)`
  ${FlagStyles}
`;

const GameHeader = () => {
  const { t } = useTranslation();

  if (getIsWebView()) {
    return (
      <Wrapper>
        <Navigation />
      </Wrapper>
    );
  }

  return (
    <GameHeaderOuter>
      <GameHeaderInner>
        <LogoFlagWrap>
          <FlagList>
            <FlagItem>
              <FlagLink href="https://fantasy.obosdamallsvenskan.se/">
                <StyledSEFlag />
              </FlagLink>
            </FlagItem>
            <FlagItem>
              <FlagLink href="https://en.fantasy.obosdamallsvenskan.se">
                <StyledGBFlag />
              </FlagLink>
            </FlagItem>
          </FlagList>
        </LogoFlagWrap>
        <AssetContainer>
          <HeaderLogo title={t("gameHeader.homeLink", "Go to home page")} />
        </AssetContainer>
        <PlayerImage />
        <Navigation />
      </GameHeaderInner>
    </GameHeaderOuter>
  );
};

export default GameHeader;

import { em } from "polished";
import styled, { css } from "styled-components/macro";

interface IProps {
  size?: string;
}

export const ScrollTable = styled.div`
  @media (max-width: ${em("480px")}) {
    /* @media (max-width: ${({ theme }) => theme.breakpoints[4]}) { */
    overflow-x: auto;

    table {
      width: 48rem;
    }
  }
`;

const Table = styled.table<IProps>`
  width: 100%;
  border-collapse: collapse;
  border-width: 0 1px;
  border-style: solid;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  border-color: transparent;

  th,
  td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteGrey};
    font-weight: bold;

    ${({ size }) =>
      size === "lg" &&
      css`
        padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
      `}
  }
  td {
    padding: ${({ theme }) => `${theme.space[2]}`};
  }

  th {
    padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
    border: 1px solid ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.black};
    text-align: start;
    text-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints[4]}) {
      word-wrap: none;
    }
  }

  tr {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export default Table;

import styled from "styled-components/macro";

interface TitleContainerProps {
  $hasMargin?: boolean;
}

const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: ${({ theme, $hasMargin = true }) =>
    $hasMargin ? theme.space[2] : "0"};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-left: ${({ theme }) => theme.space[0]};
  }
`;

export default TitleContainer;

import { RouteComponentProps } from "@reach/router";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useClipboard from "react-use-clipboard";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getLeagueForEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { ILeagueEntry } from "../../core/store/entries/types";
import { getCode } from "../../core/store/leagues/reducers";
import { fetchLeagueCode } from "../../core/store/leagues/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import Button from "../Button";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import { ControlArrowRight } from "../icons/Arrows";

const LeagueInvite = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.space[3]};
  text-align: center;
`;

const LeagueInviteCode = styled.strong`
  margin-inline-start: ${({ theme }) => theme.space[1]};
  color: ${({ theme }) => theme.colors.white};
`;

const Hr = styled.hr`
  border: 0;
  height: 1px;
  margin-top: 23px;
  margin-bottom: 23px;
  background-color: ${({ theme }) => theme.colors.grey};
`;

const inviteMessage = (code: string, leagueName: string, t: TFunction) => [
  t("invite.inviteMsg1", "Hi"),
  t("invite.inviteMsg2", "I've created a league called {{leagueName}}.", {
    leagueName: leagueName,
  }),
  `${window.location.origin}/leagues/auto-join/${code}`,
  t(
    "invite.inviteMsg3",
    "You shouldn't need the league code, but just in case here it is: {{code}}",
    {
      code: code,
    }
  ),
  t("invite.inviteMsg4", "Enjoy the game!"),
];

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  code: string | null;
  entryId: number;
  leagueNumber: number;
  league: ILeagueEntry | null;
}

interface IPropsFromDispatch {
  fetchCode: (leagueId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

const Invite: React.FC<Props> = ({
  code,
  league,
  leagueNumber,
  entryId,
  fetchCode,
  fetchEntrySummary,
}) => {
  const { t } = useTranslation();
  const codeToCopy = code ? code : "";
  const leagueName = league ? league.name : "";
  const message = inviteMessage(codeToCopy, leagueName, t);
  const emailText = message ? message.join("\n\n") : "";
  const emailBody = encodeURIComponent(emailText);
  const emailSubject = t("invite.emailSubject", "Play RSL Fantasy");
  const encodedEmailSubject = encodeURI(emailSubject);
  const joinUrl = `${window.location.origin}/leagues/auto-join/${code}`;
  const [isCopied, setCopied] = useClipboard(codeToCopy, {
    successDuration: 500,
  });
  const [isCopiedUrl, setCopiedUrl] = useClipboard(joinUrl, {
    successDuration: 500,
  });
  const [isCopiedEmail, setCopiedEmail] = useClipboard(emailText, {
    successDuration: 500,
  });

  useEffect(() => {
    if (leagueNumber) {
      fetchCode(leagueNumber);
    }

    fetchEntrySummary(entryId);
  }, [leagueNumber, entryId, fetchCode, fetchEntrySummary]);

  if (!codeToCopy || !leagueNumber || !league) {
    return null;
  }

  return (
    <Wrapper>
      <Main>
        <Copy>
          <Box mb={4}>
            <Title>
              {t("invite.title", "Invite players to join")} {leagueName}
            </Title>
          </Box>
          <LeagueInvite onDoubleClick={setCopied}>
            {t("invite.codeToJoin", "Code to join this league")}:
            <LeagueInviteCode>
              {isCopied ? t("invite.copied", "Copied!") : codeToCopy}
            </LeagueInviteCode>
          </LeagueInvite>

          <Button variant="primary" onClick={setCopiedUrl}>
            {isCopiedUrl
              ? t("invite.copied", "Copied!")
              : t("invite.copyUrl", "Copy auto-join link")}
          </Button>

          <Hr />

          <p>
            {t(
              "invite.description1",
              "When you click the link below, the link will attempt to open your default email program and start a new email."
            )}
          </p>

          <p>
            {t(
              "invite.description2",
              "If you do not have a default email program, like Outlook or Mail, enabled on your computer, clicking the link may not result in any action or you may be prompted to set up an email program."
            )}
          </p>

          <Button
            variant="primary"
            onClick={() =>
              (window.location.href = `mailto:?to=&subject=${encodedEmailSubject}&body=${emailBody}`)
            }
          >
            {t("invite.byEmailButton", "Share league code by email")}{" "}
            <ControlArrowRight />
          </Button>

          <Hr />

          <p>
            {t(
              "invite.description3",
              "If you use a browser based webmail application, like Gmail or Yahoo!, you can copy and paste the text below:"
            )}
          </p>

          <Box bg="lightGrey" p={2} mb={3} onDoubleClick={setCopiedEmail}>
            {message.map((line) => (
              <p key={line}>{line}</p>
            ))}
          </Box>
          <Box mb={4}>
            <Button onClick={setCopiedEmail}>
              {isCopiedEmail
                ? t("invite.copied", "Copied!")
                : t("invite.copyText", "Copy email text")}
            </Button>
          </Box>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export { Invite as InviteTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  const entry = player.entry;
  const leagueNumber =
    ownProps.leagueId && parseInt(ownProps.leagueId, 10)
      ? parseInt(ownProps.leagueId, 10)
      : 0;
  return {
    code: leagueNumber ? getCode(state, leagueNumber) : null,
    league: leagueNumber ? getLeagueForEntry(state, entry, leagueNumber) : null,
    leagueNumber,
    entryId: entry,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchCode: (leagueId: number) => dispatch(fetchLeagueCode(leagueId)),
  fetchEntrySummary: (entryId: number) => dispatch(fetchEntrySummary(entryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
